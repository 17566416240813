.site-footer {
  max-width: 1200px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 0.5rem 1rem 1rem;
  p {
    font-size: 0.9rem;
    line-height: 1;
    margin: 0;
  }
}
