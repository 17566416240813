/*******************
* Variables
*******************/
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0;
  height: 100%;
  background: #2f333d;
  margin: 0;
  opacity: 0;
  transform: translate3d(-20%, 0, 0);
  transition: all 400ms ease;
  color: #abb2bf; }
  .loaded .card {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
    .loaded .card:hover {
      transform: translate3d(0, 0, 0) scale(0.97); }
  .card .wrapper {
    padding: 0.75rem;
    flex-grow: 1; }
  .card .card-about {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 85px; }
  .card.page {
    height: auto; }
  .card img {
    display: block;
    width: 100%;
    height: auto; }
