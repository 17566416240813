.site-header {
  max-width: 1200px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 1rem;

  h1 {
    font-weight: 300;
  }

  p {
    margin: 0.45rem 0 0;
  }
}
