/*******************
* Variables
*******************/
.projects {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.5rem; }
  .projects .other-projects-title {
    padding: 2rem 0.5rem 0; }

.project-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .project-grid .project-grid-item {
    width: 25%;
    padding: 0.5rem; }
    @media (max-width: 1040px) {
      .project-grid .project-grid-item {
        width: 33%; } }
    @media (max-width: 760px) {
      .project-grid .project-grid-item {
        width: 50%; } }

.site-name {
  margin: 0 0 0.3rem; }

.site-meta {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 760px) {
    .site-meta {
      display: block; }
      .site-meta > span {
        display: block; } }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(40, 44, 52, 0.75);
  opacity: 0;
  transition: opacity 300ms ease; }
  .overlay span {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate3d(0, -80%, 0);
    opacity: 0;
    transition: all 300ms 300ms ease;
    color: #fff;
    text-align: center;
    font-size: 0.75rem; }
  .overlay:hover {
    opacity: 1; }
    .overlay:hover span {
      transform: translate3d(0, -50%, 0);
      opacity: 1; }

.screen-gallery {
  display: flex;
  margin-top: 1rem; }
  .screen-gallery .screen-gallery-thumb {
    width: 50px;
    cursor: pointer;
    margin-right: 1rem;
    border: 2px solid #5c6370;
    transition: all ease-in 100ms;
    outline: 0; }
    .screen-gallery .screen-gallery-thumb:hover {
      opacity: 0.5;
      transform: scale(1.1); }
    .screen-gallery .screen-gallery-thumb .small-project-image {
      display: block;
      width: 100%;
      height: auto; }
